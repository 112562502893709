const { PROD } = import.meta.env

const USE_TESTNET = import.meta.env.VITE_USE_TESTNET === "true"

const SENTRY_SUPPORT = import.meta.env.VITE_SENTRY_SUPPORT === "true"

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN

const ETH_HOSTNAME_HTTP = import.meta.env.VITE_ETH_HOSTNAME_HTTP

const REFERRAL = import.meta.env.VITE_REFERRAL

const TBTC_API_ENDPOINT = import.meta.env.VITE_TBTC_API_ENDPOINT

const GELATO_RELAY_API_KEY = import.meta.env.VITE_GELATO_RELAY_API_KEY

const SUBGRAPH_API_KEY = import.meta.env.VITE_SUBGRAPH_API_KEY

const NETWORK_TYPE = USE_TESTNET ? "testnet" : "mainnet"

const LATEST_COMMIT_HASH = import.meta.env.VITE_LATEST_COMMIT_HASH

const ACRE_API_ENDPOINT = import.meta.env.VITE_ACRE_API_ENDPOINT

export default {
  PROD,
  USE_TESTNET,
  SENTRY_SUPPORT,
  SENTRY_DSN,
  ETH_HOSTNAME_HTTP,
  REFERRAL,
  TBTC_API_ENDPOINT,
  GELATO_RELAY_API_KEY,
  SUBGRAPH_API_KEY,
  NETWORK_TYPE,
  LATEST_COMMIT_HASH,
  ACRE_API_ENDPOINT,
}
